import React from "react";
import { Link } from "gatsby";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import Posts from "../components/Posts";
import { graphql } from "gatsby";
import SeO from "../components/SEO";

export default function Home({ data }) {
  /* console.log(data) */
  const posts = data.allMdx.nodes;

  return (
    <>
      <Layout>
        <SeO title="Home" />
        <Hero />
        <Posts posts={posts} title="latest posts" />
        <div style={{ textAlign: "center", padding: "3rem" }}>
          <Link to="/posts">
            <button className="cta-action">All posts </button>
          </Link>
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    allMdx(limit: 6, sort: { fields: frontmatter___Update, order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          category
          readTime
          slug
          Update(formatString: "MMM,Do,YYYY")
        }
        excerpt
      }
    }
  }
`;
