import React from "react";
import { FaRegClock } from "react-icons/fa";
import { Link } from "gatsby";

const Post = ({ excerpt, frontmatter }) => {
  const { title, category, slug, Update, readTime } = frontmatter;
  // console.log(props)
  //   console.log(title)
  return (
    <section className="article">
      <div className="info">
        {category.map((tag, index) => {
          return (
            <span className="category" key={index}>
              {" "}
              {tag}
            </span>
          );
        })}
        <Link to={`/posts/${slug}`} className="link">
          <h3>{title}</h3>
        </Link>
        <div className="underline"></div>
        <p>{excerpt}</p>
        <footer className="footer">
          <span className="date">
            <FaRegClock className="icon"></FaRegClock>
            {Update}
          </span>
          <span>{readTime} min read</span>
        </footer>
      </div>
    </section>
  );
};

export default Post;
