import React from "react";
import Post from "./Post";
import Title from "../Banner/Title";

const Posts = ({ posts, title }) => {
  return (
    <section className="posts">
      <Title title={title} />
      <div className="post-center">
        <article className="postContainer">
          {posts.map((post, index) => {
            return <Post key={index} {...post} />;
          })}
        </article>
      </div>
    </section>
  );
};

export default Posts;
